'use client';

/* eslint-disable import/order */
import {
  ButtonSize,
  FsButton,
  FsListSelect,
  FsRadioButton,
  ListSelectType,
} from '@printi/ds-offset-react-core';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useSession } from 'next-auth/react';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { useMemo } from 'react';
import { Heading, HeadingType } from '../Heading';
import * as S from './TypeAccount.styles';
import { useTypeAccountHooks } from './TypeAccount.hooks';

export const TypeAccount = () => {
  const { isMobile } = useLayout();
  const { formik, handleChangeType } = useTypeAccountHooks();
  const { values, handleSubmit, setFieldValue } = formik;
  const { registerData } = useAuth();

  const { data: session } = useSession();
  const customSession = session as CustomSession;

  const isSocialRegister = useMemo(() => {
    return !!customSession?.profile?.id;
  }, [customSession]);

  return (
    <form>
      <Heading
        type={HeadingType.HeadingDescription2Xs}
        description={`Olá, ${isSocialRegister ? customSession?.profile?.name : registerData.name}`}
        heading="Agora escolha um tipo de conta"
      />
      <S.ListSelectGroup>
        <FsListSelect
          type={ListSelectType.Icon}
          icon="UserLine"
          heading="Pessoa Física"
          description="Para criar uma conta usando CPF"
          selected={values.typeAccount === 'PF'}
          style={{ width: '100%' }}
        >
          <FsRadioButton
            value="CPF"
            selected={values.typeAccount === 'PF'}
            inputProps={{
              onChange: () => {
                handleChangeType('PF', setFieldValue);
              },
            }}
          />
        </FsListSelect>
        <FsListSelect
          type={ListSelectType.Icon}
          icon="CompanyLine"
          heading="Pessoa Jurídica"
          description="Para criar uma conta usando CNPJ"
          selected={values.typeAccount === 'PJ'}
          style={{ width: '100%' }}
        >
          <FsRadioButton
            value="CNPJ"
            selected={values.typeAccount === 'PJ'}
            inputProps={{
              onChange: () => {
                handleChangeType('PJ', setFieldValue);
              },
            }}
          />
        </FsListSelect>
      </S.ListSelectGroup>
      <FsButton
        type="button"
        size={isMobile ? ButtonSize.SM : ButtonSize.LG}
        onClick={() => handleSubmit()}
      >
        Avançar
      </FsButton>
    </form>
  );
};
