import {
  ButtonSignInSize,
  ButtonSize,
  FsButton,
  FsButtonMini,
  FsButtonSignIn,
  FsDescription,
  FsDivider,
  FsInputText,
  FsParagraph,
} from '@printi/ds-offset-react-core';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { Heading } from '../Heading';
import { ButtonSignInVariant } from '../SignIn/SignIn.types';
import * as S from './Register.styles';
import { useRegisterHooks } from './Register.hooks';

export const Register = () => {
  const { isMobile } = useLayout();
  const { formik, isLoading } = useRegisterHooks();
  const { isValidFields, setIsValidFields } = useValidationHooks();
  const { errors, values, handleSubmit, handleChange, handleBlur } = formik;
  const { setStep } = useAuth();

  const handleValidation = () => {
    const isFormValid = Object.keys(errors).length === 0;
    const isFormFilled = Object.values(values).every(
      (value) => value.length > 0,
    );

    setIsValidFields(isFormValid && isFormFilled);
  };

  return (
    <form data-testid="register-form">
      <Heading
        heading="Cadastrar"
        description="Informe um email para iniciar seu cadastro"
      />
      <FsInputText
        id="email"
        label="Email"
        placeholder="Ex.: printi@mail.com.br"
        value={values.email}
        onChange={(e) => {
          handleChange(e);
          handleValidation();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleValidation();
        }}
        supportText={errors.email}
        error={!!errors.email}
        data-testid="register-form-email"
      />
      <FsButton
        size={isMobile ? ButtonSize.SM : ButtonSize.LG}
        onClick={() => handleSubmit()}
        disabled={!isValidFields}
        loading={isLoading}
        type="button"
        data-testid="register-form-register-btn"
      >
        Cadastrar
      </FsButton>
      <S.Divider>
        <FsDivider style={{ width: '100%' }} />
        <FsDescription>ou</FsDescription>
        <FsDivider style={{ width: '100%' }} />
      </S.Divider>
      <FsButtonSignIn
        size={isMobile ? ButtonSignInSize.SM : ButtonSignInSize.LG}
        variant={ButtonSignInVariant.Google}
        style={{ width: '100%' }}
        type="button"
        data-testid="register-form-google-btn"
      >
        Continuar com Google
      </FsButtonSignIn>
      <FsButtonSignIn
        size={isMobile ? ButtonSignInSize.SM : ButtonSignInSize.LG}
        variant={ButtonSignInVariant.Facebook}
        style={{ width: '100%' }}
        type="button"
        data-testid="register-form-facebook-btn"
      >
        Continuar com Facebook
      </FsButtonSignIn>
      <S.Footer>
        <FsParagraph>Ja possui conta?</FsParagraph>
        <FsButtonMini
          onClick={() => setStep(LoginSteps.SignIn)}
          type="button"
          data-testid="register-form-to-enter-btn"
        >
          Entrar
        </FsButtonMini>
      </S.Footer>
    </form>
  );
};
