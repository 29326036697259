// eslint-disable-next-line
import styled from 'styled-components';

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  gap: var(--spacing-3x);
  padding: var(--spacing-none);
`;

export const ListSelectGroup = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3x);
  align-self: stretch;
`;
