'use client';

import {
  ButtonSize,
  FlagType,
  FsButton,
  FsFlag,
  FsHeading,
  FsLink,
  FsParagraph,
  HeadingSize,
  IconPosition,
} from '@printi/ds-offset-react-core';
import { useEffect, useState } from 'react';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import * as S from './ResendMail.styles';
import { useResendMailHooks } from './ResendMail.hooks';

export const ResendMail = () => {
  const [timeLeft, setTimeLeft] = useState<number | null>(60);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { resendEmail, setStep } = useAuth();
  const { isLoading, recoverAccess, isSuccess } = useResendMailHooks();

  useEffect(() => {
    if (timeLeft === null) {
      return;
    }

    if (timeLeft > 0) {
      const timerId = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearTimeout(timerId);
    } else {
      setIsDisabled(false);
      setTimeLeft(null);
    }
  }, [timeLeft]);

  const onResend = () => {
    recoverAccess({ email: resendEmail });
  };

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setTimeLeft(60);
    setIsDisabled(true);
  }, [isSuccess]);

  return (
    <S.Container>
      <S.Heading>
        <FsHeading size={HeadingSize.MD}>
          Para redefinir sua senha, acesse o link enviado para seu email
        </FsHeading>
        <S.WrapperParagraph>
          <FsParagraph>Enviado para {resendEmail}</FsParagraph>
          <FsLink onClick={() => setStep(LoginSteps.RecoverAccess)}>
            Alterar email
          </FsLink>
        </S.WrapperParagraph>
      </S.Heading>
      <FsButton
        size={ButtonSize.LG}
        disabled={isDisabled || isLoading}
        type="button"
        onClick={onResend}
      >
        {timeLeft ? `${timeLeft}s ` : ''}Reenviar
      </FsButton>
      <FsFlag
        heading="Não recebeu o link?"
        type={FlagType.Informative}
        iconPosition={IconPosition.Left}
      >
        Olhe sua caixa de spam, verifique se digitou o email correto ou aguarde
        para reenviar um novo link
      </FsFlag>
    </S.Container>
  );
};
