// eslint-disable-next-line
import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  gap: var(--spacing-2x);
  padding: var(--spacing-none);
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;

  gap: var(--spacing-half) var(--spacing-1x);
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: var(--spacing-3x);
  align-self: stretch;
  flex-wrap: wrap;

  padding: var(--spacing-none);
`;

export const Error = styled.p`
  align-self: center;
  color: var(--negative-support-text-color);
  display: flex;
  font-family: var(--font-family-base);
  font-size: var(--font-size-2xs);
  font-style: normal;
  font-weight: 400;
  margin: var(--spacing-none);
`;
