import {
  ButtonSize,
  FsButton,
  FsInputText,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { Heading } from '../Heading';
import { useRecoverAccessHooks } from './RecoverAccess.hooks';

export const RecoverAccess = () => {
  const { isMobile } = useLayout();
  const { formik, isLoading } = useRecoverAccessHooks();
  const { isValidFields, setIsValidFields } = useValidationHooks();
  const { errors, values, handleSubmit, handleChange, handleBlur } = formik;

  const handleValidation = () => {
    const isFormValid = Object.keys(errors).length === 0;
    const isFormFilled = Object.values(values).every(
      (value) => value.length > 0,
    );

    setIsValidFields(isFormValid && isFormFilled);
  };

  return (
    <form>
      <Heading
        heading="Recuperar Acesso"
        description="Se o email estiver correto, iremos enviar um link de validação para
          que você possa cadastrar uma nova senha"
      />
      <FsInputText
        value={values.email}
        id="email"
        label="Email"
        placeholder="Ex.: printi@mail.com.br"
        onChange={(e) => {
          handleChange(e);
          handleValidation();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleValidation();
        }}
        supportText={errors.email}
        error={!!errors.email}
      />
      <FsButton
        size={isMobile ? ButtonSize.SM : ButtonSize.LG}
        disabled={!isValidFields}
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        loading={isLoading}
        type="submit"
      >
        Recuperar
      </FsButton>
    </form>
  );
};
