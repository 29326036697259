// eslint-disable-next-line
import styled from 'styled-components';

export const Base = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3x);
  align-self: stretch;
`;

export const InputGroup = styled.div`
  display: flex;
  padding: var(--spacing-none);
  align-items: flex-start;
  align-content: flex-start;
  gap: var(--spacing-3x);
  align-self: stretch;
  flex-wrap: wrap;
`;

export const StructureInput = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px var(--spacing-3x);
  flex: 1 0 0;
  flex-wrap: wrap;

  > div {
    display: flex;
    min-width: 228px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-1x);
    flex: 1 0 0;
  }
`;
