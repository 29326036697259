/* eslint-disable import/order */
import { useFormik } from 'formik';
import { FormEvent, useCallback, useState } from 'react';
import * as Yup from 'yup';
import useFormTransform from '@mf/common/utils/hooks/useFormTransform/index';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { signIn, signOut, useSession } from 'next-auth/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useRouter, useSearchParams } from 'next/navigation';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ToastType } from '@printi/ds-offset-react-core';
import bffAuth from '../../repositories/bff/auth/index';
import { TRegisterPayload } from '@mf/auth/repositories/bff/auth/types';
import bffCustomerData from '@mf/auth/repositories/bff/customer-data';
import { TCustomerDataPersonForm } from '@mf/auth/repositories/bff/customer-data/types';
import { AxiosError, AxiosResponse } from 'axios';

interface BffError extends AxiosError {
  response: AxiosResponse<{ message: string }>;
}

export const usePhysicalPersonHooks = () => {
  const {
    setRegisterData,
    setStep,
    baseUrl,
    setToast,
    setToastOpen,
    setToastType,
  } = useAuth();
  const { applyDateMask } = useFormTransform();
  const { birthdateField, cpfField, phoneField, birthdateFormatter } =
    useValidationHooks();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { push } = useRouter();

  const queryClient = useQueryClient();

  const { data: session } = useSession();
  const customSession = session as CustomSession;

  const { useCustomerdata, editCustomerData } = bffCustomerData({
    baseUrl: process.env.BFF_API_BASE_URL,
    token: customSession?.user?.token,
  });
  const { data: customerData } = useCustomerdata();
  const searchParams = useSearchParams();

  const { register } = bffAuth({ baseUrl });

  const reverseFormattedBirthdate = (date: string | null) => {
    if (!date) {
      return null;
    }

    return date.split('/').reverse().join('-') as string | null;
  };

  const isSocialRegister = () => {
    return !!customSession?.profile?.id;
  };

  const isCompleteRegister = useCallback(() => {
    return !!customerData?.id;
  }, [customerData?.id]);

  const handleChangeBirthday = (
    e: FormEvent<HTMLDivElement>,
    handleChange: (e: FormEvent<HTMLDivElement>) => void,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    handleChange(e);
    const value = (e.target as HTMLInputElement).value;
    setFieldValue('birthdate', applyDateMask(value));
  };

  const verifyPhoneMask = useCallback((phone: string) => {
    if (phone.length === 13) {
      return 'phone';
    } else {
      return 'cellPhone';
    }
  }, []);

  const schema = Yup.object().shape({
    cpf: Yup.string().test(
      'is-cpf-valid',
      'Por favor, digite o CPF válido',
      (value) => cpfField(value),
    ),
    phone: Yup.string().test(
      'is-phone-valid',
      'Por favor, digite o telefone',
      (value) => phoneField(value),
    ),
    birthdate: Yup.string()
      .test('is-date-invalid', 'Por favor, digite uma data válida', (value) => {
        if (!value) {
          return true;
        }

        if (value.length === 10) {
          const date = new Date(birthdateFormatter(value));
          return !isNaN(date.getTime());
        } else {
          return true;
        }
      })
      .test('is-min-age-valid', 'Você precisa ter mais de 18 anos', (value) => {
        if (!value) {
          return true;
        }

        if (value.length === 10) {
          const today = new Date();

          const birthdateDate = new Date(birthdateFormatter(value));
          const age = today.getFullYear() - birthdateDate.getFullYear();
          return age >= 18;
        } else {
          return true;
        }
      })
      .test(
        'is-max-age-valid',
        'Por favor, digite uma data válida',
        (value) => {
          if (!value) {
            return true;
          }

          if (value.length === 10) {
            return parseInt(value.slice(-4)) > 1900;
          } else {
            return true;
          }
        },
      )
      .test('is-birthdate-valid', 'Por favor, digite quando nasceu', (value) =>
        birthdateField(value),
      ),
  });

  const { mutate, isLoading: isLoadingSocialRegister } = useMutation(register, {
    onSuccess: () => {
      const fpPromise = FingerprintJS.load();

      fpPromise
        .then((fp) => fp.get())
        .then(async (result) => {
          const fingerprint = result.visitorId;

          await signIn('credentials', {
            email: customSession.profile?.email,
            password: '',
            fingerprint: fingerprint,
            redirect: true,
            callbackUrl: searchParams.get('callbackUrl') || '/',
            user_id: customSession.profile?.id,
            token: customSession.provider?.access_token,
            provider: customSession.provider?.name,
          });
        });
    },
    onError: () => {
      setStep(LoginSteps.RecoverAccess);
      setToast(
        'Você já possui uma conta. Caso tenha esquecido sua senha, recupere o acesso.',
      );
      setToastType(ToastType.Negative);
      setToastOpen(true);
    },
  });

  const {
    mutate: mutateEditCustomer,
    isLoading: isLoadingCompleteRegister,
    isError: isErrorCompleteRegister,
  } = useMutation(editCustomerData, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customer-data'],
        exact: true,
      });
      push(searchParams.get('callbackUrl') || '/');
    },
    onError: (error) => {
      const e = error as BffError;

      if (
        e?.response?.data?.message === 'Internal server error' ||
        e?.response?.status === 400
      ) {
        setToast('Erro ao atualizar os dados.');
        setToastType(ToastType.Negative);
        setToastOpen(true);

        queryClient.setQueryData(['customer-data'], null);
        signOut({
          redirect: false,
        });

        setTimeout(() => {
          setToast('');
          setToastOpen(false);
          queryClient.invalidateQueries({
            queryKey: ['customer-data'],
            exact: true,
          });
          push('/');
        }, 3000);
      }
    },
  });

  const registerUser = useCallback(
    (payload: TRegisterPayload) => {
      mutate({ payload: payload });
    },
    [mutate],
  );

  const completeRegister = useCallback(
    (payload: TCustomerDataPersonForm) => {
      mutateEditCustomer(payload);
    },
    [mutateEditCustomer],
  );

  const formik = useFormik({
    initialValues: {
      cpf: '',
      phone: '',
      birthdate: '',
      occupation: '',
      receiveOffers: false,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (isSocialRegister()) {
        const fpPromise = FingerprintJS.load();

        fpPromise
          .then((fp) => fp.get())
          .then((result) => {
            const fingerprint = result.visitorId;
            const formattedBirthdate = values.birthdate
              ?.split('/')
              .reverse()
              .join('-');

            const payload = {
              password: '',
              name: customSession.profile?.name || '',
              email: customSession.profile?.email || '',
              email_confirmation: customSession.profile?.email || '',
              type: 'PF',
              cpf: values.cpf,
              phone: values.phone,
              birthdate: formattedBirthdate,

              terms_of_use: true,
              privacy_police: true,
              exclusive_offers_subscribe: values.receiveOffers,
              fingerprint: JSON.stringify({ fingerprint }),

              social_data: {
                id: customSession.profile?.id || '',
                token: customSession.provider?.access_token || '',
              },
              social_type: customSession.provider?.name,
            };

            registerUser(payload);
          });
      } else if (isCompleteRegister()) {
        const payload: TCustomerDataPersonForm = {
          id: customerData?.id || '',
          first_name: customerData?.first_name || '',
          last_name: customerData?.last_name || '',
          cpf: values.cpf || '',
          birthdate: reverseFormattedBirthdate(values.birthdate) || '',
          email: customerData?.email || '',
          phone: values.phone || '',
          other_phone: customerData?.other_phone || null,
          type: 'PF',
        };

        completeRegister(payload);
      } else {
        setIsLoading(true);
        setRegisterData((state) => ({
          ...state,
          cpf: values.cpf,
          phone: values.phone,
          occupation: values.occupation,
          birthdate: values.birthdate,
          receiveOffers: values.receiveOffers,
        }));

        await new Promise((resolve) => setTimeout(resolve, 1000));

        setStep(LoginSteps.CreatePassword);
        setIsLoading(false);
      }
    },
  });

  return {
    formik,
    verifyPhoneMask,
    handleChangeBirthday,
    isLoading,
    isLoadingSocialRegister,
    isSocialRegister,
    isCompleteRegister,
    customerData,
    isLoadingCompleteRegister,
    isErrorCompleteRegister,
  };
};
