'use client';

import {
  ButtonSize,
  FsButton,
  FsCheckbox,
  FsInputSelect,
  FsInputText,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import React, { useEffect, useMemo } from 'react';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { useSession } from 'next-auth/react';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { Heading, HeadingType } from '../Heading';
import * as S from './LegalPerson.styles';
import { useLegalPersonHooks } from './LegalPerson.hooks';

export const LegalPerson = () => {
  const employersOptions = useMemo(() => {
    return [
      { id: '1', name: '1 a 10 funcionários' },
      { id: '11', name: '11 a 50 funcionários' },
      { id: '51', name: '51 a 100 funcionários' },
      { id: '101', name: '101 a 500 funcionários' },
      { id: '501', name: '501 a 1.000 funcionários' },
      { id: '1000', name: 'Mais de 1.000 funcionários' },
    ];
  }, []);

  const states = useMemo(() => {
    return [
      'Isento',
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RJ',
      'RN',
      'RS',
      'RO',
      'RR',
      'SC',
      'SP',
      'SE',
      'TO',
    ];
  }, []);

  const { isMobile } = useLayout();
  const {
    verifyPhoneMask,
    formik,
    isLoading,
    isCompleteRegister,
    customerData,
    isLoadingSocialRegister,
    isLoadingCompleteRegister,
  } = useLegalPersonHooks();
  const { isValidFields, setIsValidFields } = useValidationHooks();

  const { data: session } = useSession();
  const customSession = session as CustomSession;

  const { errors, values, handleSubmit, handleChange, setFieldValue } = formik;

  // eslint-disable-next-line
  const handleSelectedEmployees = (e: any) => {
    const selectedId = e?.target?.value?.props?.value;
    const selectedEmployee =
      employersOptions.find((option) => option.id === selectedId) || null;

    setFieldValue('numberOfEmployees', selectedEmployee?.id);
  };

  // eslint-disable-next-line
  const handleSelectedStateRegistration = (e: any) => {
    const selectedId = e?.target?.value;

    const selectedState =
      states.find((option) => option === selectedId) || null;

    values.stateRegistration = '';
    setFieldValue('stateRegistrationId', selectedState);
  };

  const handleValidation = () => {
    const isFormValid = Object.keys(errors).length === 0;

    const inputs = {
      cnpj: values.cnpj,
      corporateReason: values.corporateReason,
      phone: values.phone,
      numberOfEmployees: values.numberOfEmployees,
      stateRegistrationId: values.stateRegistrationId,
    };

    const isFormFilled = Object.values(inputs).every((value) => {
      if (
        values.stateRegistrationId === 'Isento' &&
        values.stateRegistration.length === 0 &&
        value.length > 0
      ) {
        return true;
      } else {
        if (values.stateRegistration.length > 0 && value.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    });

    setIsValidFields(isFormValid && isFormFilled);
  };

  const getEmployerName = (id: string) => {
    const option = employersOptions.find((option) => option.id === id);
    return option ? option.name : '';
  };

  useEffect(() => {
    if (isCompleteRegister()) {
      setFieldValue('cnpj', customerData?.cnpj || '');
      setFieldValue('phone', customerData?.phone || '');
      setFieldValue('corporateReason', customerData?.company_name || '');
      setFieldValue(
        'stateRegistration',
        customerData?.state_registration || '',
      );
      setFieldValue(
        'receiveOffers',
        customerData?.exclusive_offers_subscribe === '1',
      );

      const selectedEmployee =
        employersOptions.find(
          (option) => option.id === customerData?.employee_number,
        ) || null;

      if (selectedEmployee) {
        setFieldValue('numberOfEmployees', selectedEmployee.id);
      }

      const selectedState =
        states.find(
          (option) => option === customerData?.id_state_registration,
        ) || null;

      if (selectedState) {
        setFieldValue('stateRegistrationId', selectedState);
      }
      handleValidation();
    }
    // eslint-disable-next-line
  }, [
    isCompleteRegister,
    customerData,
    setFieldValue,
    employersOptions,
    states,
  ]);

  useEffect(() => {
    handleValidation();
    // eslint-disable-next-line
  }, [values, errors]);

  const isSocialRegister = useMemo(() => {
    return !!customSession?.profile?.id;
  }, [customSession]);

  return (
    <form>
      <Heading
        type={HeadingType.HeadingHeadingDescription}
        heading2XS="Pessoa Jurídica"
        heading="Complete seus dados"
        description="Para ativar sua conta para compras no site."
      />
      <S.Base>
        <FsInputText
          id="cnpj"
          label="CNPJ"
          placeholder="00.000.000/0000-00"
          supportText={errors.cnpj}
          error={!!errors.cnpj}
          onChange={(e) => {
            handleChange(e);
            handleValidation();
          }}
          onBlur={(e) => {
            handleChange(e);
            handleValidation();
          }}
          maskType={'cnpj'}
          value={values.cnpj}
        />
        <S.InputGroup>
          <S.StructureInput>
            <FsInputText
              id="corporateReason"
              label="Razão Social"
              placeholder="Ex: Printi Ltda."
              supportText={errors.corporateReason}
              error={!!errors.corporateReason}
              onChange={handleChange}
              value={values.corporateReason}
            />
            <FsInputText
              id="phone"
              label="Telefone"
              placeholder="(00) 00000-0000"
              supportText={errors.phone}
              error={!!errors.phone}
              onChange={handleChange}
              maskType={verifyPhoneMask(values.phone)}
              value={values.phone}
            />
          </S.StructureInput>
        </S.InputGroup>
        <FsInputSelect
          id="numberOfEmployees"
          label="N° de funcionários"
          placeholder="Escolha uma opção"
          error={!!errors.numberOfEmployees}
          supportText={errors.numberOfEmployees}
          onChange={handleSelectedEmployees}
          value={getEmployerName(values.numberOfEmployees)}
        >
          {employersOptions.map((option) => (
            <React.Fragment key={option.id}>
              <option
                key={option.id}
                value={option.id}
                style={{
                  display: 'contents',
                }}
              >
                {option.name}
              </option>
            </React.Fragment>
          ))}
        </FsInputSelect>
        <S.InputGroup>
          <S.StructureInput>
            <FsInputSelect
              id="stateRegistrationId"
              label="Inscrição estadual"
              placeholder="Escolher"
              error={!!errors.stateRegistrationId}
              supportText={errors.stateRegistrationId}
              onChange={handleSelectedStateRegistration}
              value={values.stateRegistrationId}
              style={{
                margin: 'var(--spacing-1x) 0',
              }}
            >
              {states.map((option, i) => (
                <React.Fragment key={i}>{option}</React.Fragment>
              ))}
            </FsInputSelect>
            <FsInputText
              id="stateRegistration"
              label="Número"
              placeholder=""
              disabled={
                values.stateRegistrationId === undefined ||
                values.stateRegistrationId === '' ||
                values.stateRegistrationId === 'Isento'
                  ? true
                  : false
              }
              supportText={errors.stateRegistration}
              error={!!errors.stateRegistration}
              onChange={handleChange}
              value={values.stateRegistration}
            />
          </S.StructureInput>
        </S.InputGroup>
        <FsCheckbox
          inputProps={{
            id: 'receiveOffers',
          }}
          selected={values.receiveOffers}
          onChange={handleChange}
        >
          Receber ofertas exclusivas e novidades por e-mail
        </FsCheckbox>
      </S.Base>
      <FsButton
        size={isMobile ? ButtonSize.SM : ButtonSize.LG}
        onClick={() => handleSubmit()}
        disabled={!isValidFields}
        loading={
          isLoading || isLoadingCompleteRegister || isLoadingSocialRegister
        }
        type="button"
      >
        {isCompleteRegister() || isSocialRegister ? 'Concluir' : 'Avançar'}
      </FsButton>
    </form>
  );
};
