'use client';

import {
  ButtonSize,
  FsButton,
  FsInputPassword,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { Heading } from '../Heading';
import { RequirementPassword } from '../RequirementPassword';
import { useCreatePasswordHooks } from './CreatePassword.hooks';

export const CreatePassword = () => {
  const { isMobile } = useLayout();
  const { formik, isLoading, isSuccess } = useCreatePasswordHooks();
  const { values, handleChange, errors, handleSubmit } = formik;

  return (
    <form>
      <Heading
        heading="Crie uma senha"
        description="Utilize no mínimo 7 letras, contendo ao menos 1 maiúscula, 1 número e
          1 caractere especial."
      />
      <FsInputPassword
        label="Senha"
        placeholder="Digite sua senha"
        id="password"
        onChange={handleChange}
        value={values.password}
        hidden
      />
      <RequirementPassword password={values.password} />
      <FsInputPassword
        id="confirmPassword"
        label="Confirmar senha"
        placeholder="Repita sua senha"
        disabled={!!errors.password}
        onChange={handleChange}
        error={!!errors.confirmPassword}
        supportText={errors.confirmPassword}
        hidden
      />
      <FsButton
        size={isMobile ? ButtonSize.SM : ButtonSize.LG}
        onClick={() => handleSubmit()}
        type="button"
        loading={isLoading || isSuccess}
      >
        Avançar
      </FsButton>
    </form>
  );
};
