import {
  ButtonSize,
  FsButton,
  FsInputText,
} from '@printi/ds-offset-react-core';
import useFormTransform from '@mf/common/utils/hooks/useFormTransform/index';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { Heading, HeadingType } from '../Heading';
import { useRegisterNameHooks } from './RegisterName.hooks';

export const RegisterName = () => {
  const { isMobile } = useLayout();
  const { maskSecretEmail } = useFormTransform();
  const { formik, isLoading } = useRegisterNameHooks();
  const { isValidFields, setIsValidFields } = useValidationHooks();
  const { errors, values, handleSubmit, handleChange, handleBlur } = formik;
  const { registerData } = useAuth();

  const handleValidation = () => {
    const isFormValid = Object.keys(errors).length === 0;
    const isFormFilled = Object.values(values).every(
      (value) => value.length > 0,
    );

    setIsValidFields(isFormValid && isFormFilled);
  };

  return (
    <form>
      <Heading
        type={HeadingType.DescriptionHeading}
        description={maskSecretEmail(registerData.email)}
        heading="Vamos lá! Comece nos dizendo como você se chama"
      />
      <FsInputText
        id="name"
        label="Nome completo"
        placeholder="Ex.: Maria Aparecida Silva"
        supportText={errors.name}
        error={!!errors.name}
        value={values.name}
        onChange={(e) => {
          handleChange(e);
          handleValidation();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleValidation();
        }}
      />
      <FsButton
        size={isMobile ? ButtonSize.SM : ButtonSize.LG}
        onClick={() => handleSubmit()}
        disabled={!isValidFields}
        loading={isLoading}
        type="button"
      >
        Avançar
      </FsButton>
    </form>
  );
};
