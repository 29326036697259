'use client';

import { FormEvent, useCallback, useEffect, useState } from 'react';
import useFormTransform from '@mf/common/utils/hooks/useFormTransform';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { signIn } from 'next-auth/react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { useRouter, useSearchParams } from 'next/navigation';
import { useValidationHooks } from '@mf/common/utils/hooks';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';

export const useSignInHooks = () => {
  const { toLowerCase } = useFormTransform();
  const router = useRouter();
  const searchParams = useSearchParams();
  const callbackUrl = searchParams.get('callbackUrl');
  const { setStep } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const { defaultField, emailField, setIsValidFields } = useValidationHooks();

  const handleChangeEmail = (
    e: FormEvent<HTMLDivElement>,
    handleChange: (e: FormEvent<HTMLDivElement>) => void,
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    handleChange(e);
    const value = (e.target as HTMLInputElement).value;
    setFieldValue('email', toLowerCase(value));
  };

  const schema = Yup.object().shape({
    email: Yup.string().test(
      'is-email-valid',
      'Digite um email válido.',
      (value) => emailField(value),
    ),
    password: Yup.string().test(
      'is-email-valid',
      'Digite sua senha para avançar',
      (value) => defaultField(value),
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      const fpPromise = FingerprintJS.load();

      fpPromise
        .then((fp) => fp.get())
        .then(async (result) => {
          setIsLoading(true);
          const fingerprint = result.visitorId;

          try {
            const response = await signIn('credentials', {
              ...values,
              fingerprint,
              redirect: false,
              callbackUrl: searchParams.get('callbackUrl') || '/',
            });

            if (response && !response.ok) {
              setError('E-mail e/ou senha Incorretos');
              setIsValidFields(false);

              setTimeout(() => {
                setError('');
              }, 1750);
            } else {
              if (callbackUrl) {
                setTimeout(() => {
                  window.location.href = callbackUrl;
                }, 1500);
              } else {
                setTimeout(() => {
                  window.location.href = '/';
                }, 1500);
              }

              setError('');
            }
          } catch {
            setError('Usuário e/ou senha Incorretos');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  const tryToLoginOrSendToRegister = useCallback(
    ({
      email,
      user_id,
      token,
      provider,
    }: {
      email: string;
      user_id: string;
      token: string;
      provider: string;
    }) => {
      const fpPromise = FingerprintJS.load();

      fpPromise
        .then((fp) => fp.get())
        .then(async (result) => {
          setIsLoading(true);
          const fingerprint = result.visitorId;

          try {
            const response = await signIn('credentials', {
              email,
              user_id,
              token,
              provider,
              fingerprint,
              redirect: false,
              callbackUrl: searchParams.get('callbackUrl') || '/',
            });

            if (response && !response.ok) {
              setStep(LoginSteps.TypeAccount);
            } else {
              if (callbackUrl) {
                router.push(callbackUrl);
              } else {
                router.push('/');
              }
            }
          } catch {
            setStep(LoginSteps.TypeAccount);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [callbackUrl, router, searchParams, setStep],
  );

  useEffect(() => {
    const emailInput = document.getElementById('email') as HTMLInputElement;
    const passwordInput = document.getElementById(
      'password',
    ) as HTMLInputElement;

    if (emailInput && passwordInput) {
      // Checks if fields have auto-filled values
      if (emailInput.value && !formik.values.email) {
        formik.setFieldValue('email', emailInput.value);
      }
      if (passwordInput.value && !formik.values.password) {
        formik.setFieldValue('password', passwordInput.value);
      }
    }
  }, [formik, formik.values.email, formik.values.password]);

  return {
    handleChangeEmail,
    formik,
    isLoading,
    error,
    tryToLoginOrSendToRegister,
  };
};
