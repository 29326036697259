import { useAuth } from '@mf/common/contexts/auth/auth';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { TForgotPasswordPayload } from '@mf/auth/repositories/bff/auth/types';
import bffAuth from '../../repositories/bff/auth/index';

export const useResendMailHooks = () => {
  const { baseUrl } = useAuth();
  const { forgotPassword } = bffAuth({ baseUrl });

  const { mutate, isLoading, isSuccess } = useMutation(forgotPassword);

  const recoverAccess = useCallback(
    (payload: TForgotPasswordPayload) => {
      mutate({ payload: payload });
    },
    [mutate],
  );

  return {
    recoverAccess,
    isLoading,
    isSuccess,
  };
};
