// eslint-disable-next-line
import styled from 'styled-components';

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  gap: var(--spacing-2x);
  padding: var(--spacing-none);
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;

  gap: var(--spacing-half) var(--spacing-1x);
`;
