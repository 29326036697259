import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useCallback } from 'react';

const useLoginHooks = () => {
  const { step, setProgress, setImage } = useAuth();

  const updateProgress = useCallback(() => {
    if (step === LoginSteps.RegisterName) {
      setProgress(25);
    } else if (step === LoginSteps.TypeAccount) {
      setProgress(50);
    } else if (
      [LoginSteps.PhysicalPerson, LoginSteps.LegalPerson].includes(step)
    ) {
      setProgress(75);
    } else if (step === LoginSteps.CreatePassword) {
      setProgress(100);
    } else {
      setProgress(0);
    }
  }, [setProgress, step]);

  const updateImage = useCallback(() => {
    if ([LoginSteps.SignIn, LoginSteps.RecoverAccess].includes(step)) {
      setImage(
        'https://d1br4h274rc9sc.cloudfront.net/content/Login1_f469fbcb10.png',
      );
    } else {
      setImage(
        'https://d1br4h274rc9sc.cloudfront.net/content/Login2_bc6abb4e3c.png',
      );
    }
  }, [setImage, step]);

  return {
    updateProgress,
    updateImage,
  };
};

export default useLoginHooks;
