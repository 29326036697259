import { useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { useMutation } from '@tanstack/react-query';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import bffAuth from '../../repositories/bff/auth/index';
import { TForgotPasswordPayload } from '../../repositories/bff/auth/types';

export const useRecoverAccessHooks = () => {
  const { baseUrl, setStep, setResendEmail } = useAuth();
  const { forgotPassword } = bffAuth({ baseUrl });
  const { emailField } = useValidationHooks();

  const { mutate, isLoading } = useMutation(forgotPassword, {
    onError: (error) => {
      // eslint-disable-next-line
      console.log(error);
    },
    onSuccess: () => {
      setStep(LoginSteps.ResendMail);
    },
  });

  const recoverAccess = useCallback(
    (payload: TForgotPasswordPayload) => {
      mutate({ payload: payload });
    },
    [mutate],
  );

  const schema = Yup.object().shape({
    email: Yup.string().test(
      'is-email-valid',
      'Digite um email válido.',
      (value) => emailField(value),
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setResendEmail(values.email);
      recoverAccess({
        email: values.email,
      });
    },
  });

  return {
    isLoading,
    formik,
  };
};
