import { states } from '@mf/common/constants/estados';

const useFormTransform = () => {
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const toLowerCase = (text: string): string => text.toLowerCase();

  const applyDateMask = (date: string): string => {
    date = date.replace(/\D/g, '');

    if (date.length > 8) {
      date = date.substring(0, 8);
    }

    if (date.length > 4) {
      date = date.replace(/^(\d{2})(\d{2})(\d{0,4}).*/, '$1/$2/$3');
    } else if (date.length > 2) {
      date = date.replace(/^(\d{2})(\d{0,2}).*/, '$1/$2');
    }

    return date;
  };

  const maskSecretEmail = (email: string) => {
    const atIndex = email.indexOf('@');

    if (atIndex <= 1) {
      return email;
    }

    const namePart = email.substring(0, atIndex);
    const domainPart = email.substring(atIndex);

    if (namePart.length <= 2) {
      return email;
    }

    const maskedName =
      namePart[0] +
      '*'.repeat(namePart.length - 2) +
      namePart[namePart.length - 1];
    return maskedName + domainPart;
  };

  const cleanCep = (cep: string) => {
    return cep.replace(/\D/g, '');
  };

  const cepValidation = (value: string | undefined): boolean => {
    const cep = value?.replace(/\D/g, '');

    if (!cep || cep.length === 0) {
      return true;
    }

    return cep.length === 8;
  };

  const nameValidation = (value: string | undefined): boolean => {
    if (!value) {
      return true;
    }
    const receiverName = value.trim().split(' ');
    const firtName = receiverName[0]?.length >= 2;
    const lastName = receiverName[1]?.length >= 2;

    return firtName && lastName;
  };

  const stateValidation = (value: string) => {
    return states.includes(value);
  };

  return {
    validateEmail,
    toLowerCase,
    applyDateMask,
    maskSecretEmail,
    cleanCep,
    cepValidation,
    nameValidation,
    stateValidation,
  };
};

export default useFormTransform;
