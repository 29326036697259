'use client';

import {
  ButtonSignInSize,
  ButtonSize,
  FsButton,
  FsButtonMini,
  FsButtonSignIn,
  FsDescription,
  FsDivider,
  FsInputPassword,
  FsInputText,
  FsParagraph,
} from '@printi/ds-offset-react-core';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { useLayout } from '@mf/common/components/Media/Layout';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { signIn, useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { Heading } from '../Heading';
import * as S from './SignIn.styles';
import { useSignInHooks } from './SignIn.hooks';
import { ButtonSignInVariant } from './SignIn.types';

export const SignIn = () => {
  const [loadingSocialLogin, setLoadingSocialLogin] = useState(false);
  const { isMobile } = useLayout();
  const { formik, isLoading, error, tryToLoginOrSendToRegister } =
    useSignInHooks();
  const { setIsValidFields } = useValidationHooks();
  const { errors, values, handleSubmit, handleBlur, handleChange } = formik;
  const { data: session } = useSession();
  const customSession = session as CustomSession;

  const { setStep } = useAuth();

  const handleValidation = () => {
    const isFormValid = Object.keys(errors).length === 0;
    const isFormFilled = Object.values(values).every(
      (value) => value.length > 0,
    );

    setIsValidFields(isFormValid && isFormFilled);
  };

  useEffect(() => {
    handleValidation();
  });

  useEffect(() => {
    if (!customSession) {
      return;
    }

    if (
      customSession.provider?.name === 'google' ||
      customSession.provider?.name === 'facebook'
    ) {
      setTimeout(() => {
        setLoadingSocialLogin(false);
        tryToLoginOrSendToRegister({
          user_id: customSession?.profile?.id || '',
          email: customSession?.profile?.email || '',
          token: customSession?.provider?.access_token || '',
          provider: customSession?.provider?.name || '',
        });
      }, 300);
    }
    // eslint-disable-next-line
  }, [customSession]);

  useEffect(() => {
    setLoadingSocialLogin(false);
  }, [setLoadingSocialLogin]);

  return (
    <form data-testid="signin-form">
      <Heading heading="Entrar" description="Acesse sua conta Printi." />
      <FsInputText
        value={values.email}
        id="email"
        data-testid="signin-email"
        label="Email"
        placeholder="Ex.: printi@mail.com.br"
        onChange={(e) => {
          handleChange(e);
          handleValidation();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleValidation();
        }}
        supportText={errors.email}
        error={!!errors.email || !!error}
      />
      <FsInputPassword
        value={values.password}
        id="password"
        label="Senha"
        data-testid="signin-password"
        placeholder="Digite sua senha"
        onChange={(e) => {
          handleChange(e);
          handleValidation();
        }}
        onBlur={(e) => {
          handleBlur(e);
          handleValidation();
        }}
        supportText={errors.password}
        error={!!errors.password || !!error}
        hidden
      />

      {error && <S.Error data-testid="login-error-message">{error}</S.Error>}

      <S.Actions>
        <FsButton
          size={isMobile ? ButtonSize.SM : ButtonSize.LG}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          type="submit"
          loading={isLoading}
          data-testid="signin-submit-btn"
        >
          Acessar
        </FsButton>
        <FsButtonMini
          type="button"
          data-testid="signin-recover-btn"
          onClick={() => setStep(LoginSteps.RecoverAccess)}
        >
          Recuperar senha
        </FsButtonMini>
      </S.Actions>

      <S.Divider>
        <FsDivider style={{ width: '100%' }} />
        <FsDescription>ou</FsDescription>
        <FsDivider style={{ width: '100%' }} />
      </S.Divider>
      <FsButtonSignIn
        size={isMobile ? ButtonSignInSize.SM : ButtonSignInSize.LG}
        variant={ButtonSignInVariant.Google}
        style={{ width: '100%' }}
        type="button"
        onClick={() => {
          setLoadingSocialLogin(true);
          signIn('google');
        }}
        loading={isLoading || loadingSocialLogin}
        data-testid="signin-google-btn"
      >
        Continuar com Google
      </FsButtonSignIn>
      <FsButtonSignIn
        size={isMobile ? ButtonSignInSize.SM : ButtonSignInSize.LG}
        variant={ButtonSignInVariant.Facebook}
        style={{ width: '100%' }}
        type="button"
        onClick={() => {
          setLoadingSocialLogin(true);
          signIn('facebook');
        }}
        loading={isLoading || loadingSocialLogin}
        data-testid="signin-facebook-btn"
      >
        Continuar com Facebook
      </FsButtonSignIn>
      <S.Footer>
        <FsParagraph>Não possui conta?</FsParagraph>
        <FsButtonMini
          onClick={() => setStep(LoginSteps.Register)}
          data-testid="signin-register-btn"
        >
          Cadastrar
        </FsButtonMini>
      </S.Footer>
    </form>
  );
};
