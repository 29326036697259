import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useFormik } from 'formik';

type TFormikValues = {
  typeAccount: 'PF' | 'PJ';
};

export const useTypeAccountHooks = () => {
  const { setRegisterData, setStep } = useAuth();

  const handleChangeType = (
    value: 'PF' | 'PJ',
    setFieldValue: (
      field: string,
      value: 'PF' | 'PJ',
      shouldValidate?: boolean,
    ) => void,
  ) => {
    setFieldValue('typeAccount', value);
  };

  const initialValues: TFormikValues = {
    typeAccount: 'PF',
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      setRegisterData((state) => ({
        ...state,
        typeAccount: values.typeAccount,
      }));

      if (values.typeAccount === 'PF') {
        setStep(LoginSteps.PhysicalPerson);
      } else {
        setStep(LoginSteps.LegalPerson);
      }
    },
  });

  return {
    formik,
    handleChangeType,
  };
};
