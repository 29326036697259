import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

export const useRegisterNameHooks = () => {
  const { setRegisterData, setStep } = useAuth();
  const { nameField } = useValidationHooks();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const schema = Yup.object().shape({
    name: Yup.string().test(
      'is-name-valid',
      'Por favor, escreva seu nome completo.',
      (value) => nameField(value),
    ),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setRegisterData((state) => ({
        ...state,
        name: values.name,
      }));

      await new Promise((resolve) => setTimeout(resolve, 1000));

      setStep(LoginSteps.TypeAccount);
      setIsLoading(false);
    },
  });

  return {
    formik,
    isLoading,
  };
};
