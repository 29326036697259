import { useState } from 'react';

export const useValidationHooks = () => {
  const [isValidFields, setIsValidFields] = useState<boolean>(false);

  const defaultField = (
    value: string | undefined,
    length: number = 2,
  ): boolean => {
    if (!value || value.length === 0) {
      return true;
    }

    return value.length >= length;
  };

  const cepField = (value: string | undefined): boolean => {
    const cep = value?.replace(/\D/g, '');

    if (!cep || cep.length === 0) {
      return true;
    }

    return cep.length === 8;
  };

  const nameField = (value: string | undefined): boolean => {
    if (!value || value.length < 2 || value.length === 0) {
      return true;
    }

    const receiverName = value.trim().split(' ');
    const firtName = receiverName[0] ? receiverName[0].length >= 2 : false;
    const lastName = receiverName[1] ? receiverName[1]?.length >= 2 : false;

    return firtName && lastName;
  };

  const emailField = (email: string | undefined): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || email.length === 0) {
      return true;
    }

    return emailRegex.test(email);
  };

  const cpfField = (cpf: string | undefined): boolean => {
    if (!cpf || cpf.length === 0) {
      return true;
    }

    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf.match(/(\d)\1{9}/)) {
      return false;
    }

    const values = cpf.split('').map((el) => +el);

    const rest = (count: number) =>
      ((values
        .slice(0, count - 12)
        .reduce((soma, el, index) => soma + el * (count - index), 0) *
        10) %
        11) %
      10;

    return rest(10) === values[9] && rest(11) === values[10];
  };

  const phoneField = (phone: string | undefined): boolean => {
    if (!phone || phone.length === 0) {
      return true;
    }

    phone = phone.replace(/\D/g, '');

    return phone.length >= 10;
  };

  const birthdateField = (birthdate: string | undefined): boolean => {
    if (!birthdate || birthdate.length === 0) {
      return true;
    }

    const today = new Date();
    const birthdateDate = new Date(birthdateFormatter(birthdate));
    const age = today.getFullYear() - birthdateDate.getFullYear();
    birthdate = birthdate.replace(/\D/g, '');

    return age >= 18 && birthdate.length >= 8;
  };

  const cnpjField = (cnpj: string | undefined): boolean => {
    if (!cnpj || cnpj.length === 0) {
      return true;
    }

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14) {
      return false;
    }

    const validate = (cnpj: string) => {
      const validateFirstDigit = (cnpj: string) => {
        let sum = 0;
        let weight = 5;

        for (let i = 0; i < 12; i++) {
          sum += parseInt(cnpj[i] ?? '0') * weight;
          weight--;
          if (weight === 1) {
            weight = 9;
          }
        }

        const rest = sum % 11;

        return rest < 2 ? 0 : 11 - rest;
      };

      const validateSecondDigit = (cnpj: string) => {
        let sum = 0;
        let weight = 6;

        for (let i = 0; i < 13; i++) {
          sum += parseInt(cnpj[i] ?? '0') * weight;
          weight--;
          if (weight === 1) {
            weight = 9;
          }
        }

        const rest = sum % 11;

        return rest < 2 ? 0 : 11 - rest;
      };

      return (
        validateFirstDigit(cnpj) === parseInt(cnpj[12] ?? '0') &&
        validateSecondDigit(cnpj) === parseInt(cnpj[13] ?? '0')
      );
    };

    return validate(cnpj);
  };

  const birthdateFormatter = (birthdate: string) => {
    const date = birthdate.split('/');
    return `${date[1]}/${date[0]}/${date[2]}`;
  };

  return {
    birthdateField,
    cepField,
    cpfField,
    cnpjField,
    defaultField,
    emailField,
    nameField,
    phoneField,
    birthdateFormatter,

    isValidFields,
    setIsValidFields,
  };
};
