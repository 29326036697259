'use client';

import {
  ButtonSize,
  FsButton,
  FsCheckbox,
  FsInputText,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { useEffect } from 'react';
import { Heading, HeadingType } from '../Heading';
import * as S from './PhysicalPerson.styles';
import { usePhysicalPersonHooks } from './PhysicalPerson.hooks';

export const PhysicalPerson = () => {
  const { isMobile } = useLayout();
  const {
    formik,
    isLoading,
    verifyPhoneMask,
    isLoadingSocialRegister,
    isSocialRegister,
    isCompleteRegister,
    customerData,
    isLoadingCompleteRegister,
  } = usePhysicalPersonHooks();
  const { isValidFields, setIsValidFields, cpfField } = useValidationHooks();
  const {
    errors,
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formik;

  const formattedBirthdate = (date: string) => {
    return date.split('-').reverse().join('/') as string;
  };

  const handleValidation = () => {
    const isFormValid = Object.keys(errors).length === 0;
    const inputs = {
      cpf: values.cpf,
      phone: values.phone,
      birthdate: values.birthdate,
    };
    const cpfIsValid = cpfField(values.cpf);

    const isFormFilled = Object.values(inputs).every(
      (value) => value?.length > 0,
    );

    setIsValidFields(isFormValid && isFormFilled && cpfIsValid);
  };

  useEffect(() => {
    if (isCompleteRegister()) {
      setFieldValue('cpf', customerData?.cpf || '');
      setFieldValue('phone', customerData?.phone || '');
      setFieldValue(
        'birthdate',
        formattedBirthdate(customerData?.birthdate || ''),
      );
      setFieldValue(
        'receiveOffers',
        customerData?.exclusive_offers_subscribe === '1',
      );
    }
  }, [isCompleteRegister, customerData, setFieldValue]);

  return (
    <form>
      <Heading
        type={HeadingType.HeadingHeadingDescription}
        heading2XS="Pessoa Física"
        heading="Complete seus dados"
        description="Para ativar sua conta para compras no site."
      />
      <S.Base>
        <FsInputText
          value={values.cpf}
          id="cpf"
          label="CPF"
          placeholder="000.000.000-00"
          supportText={errors.cpf}
          error={!!errors.cpf}
          maskType={'cpf'}
          onChange={(e) => {
            handleChange(e);
            handleValidation();
          }}
          onBlur={(e) => {
            handleBlur(e);
            handleValidation();
          }}
        />
        <S.InputGroup>
          <S.StructureInput>
            <FsInputText
              value={values.phone}
              id="phone"
              label="Telefone principal"
              placeholder="(00) 00000-0000"
              supportText={errors.phone}
              error={!!errors.phone}
              maskType={verifyPhoneMask(values.phone)}
              onChange={(e) => {
                handleChange(e);
                handleValidation();
              }}
              onBlur={(e) => {
                handleBlur(e);
                handleValidation();
              }}
            />
            <FsInputText
              id="birthdate"
              label="Data de Nascimento"
              placeholder="DD/MM/AAAA"
              supportText={errors.birthdate}
              error={!!errors.birthdate}
              value={values.birthdate}
              maskType={'birthDate'}
              onChange={(e) => {
                handleChange(e);
                handleValidation();
              }}
              onBlur={(e) => {
                handleBlur(e);
                handleValidation();
              }}
            />
          </S.StructureInput>
        </S.InputGroup>
        <FsCheckbox
          inputProps={{
            id: 'receiveOffers',
          }}
          selected={values.receiveOffers}
          onChange={handleChange}
        >
          Receber ofertas exclusivas e novidades por e-mail
        </FsCheckbox>
      </S.Base>
      <FsButton
        size={isMobile ? ButtonSize.SM : ButtonSize.LG}
        type="button"
        onClick={() => handleSubmit()}
        disabled={!isValidFields}
        loading={
          isLoading || isLoadingSocialRegister || isLoadingCompleteRegister
        }
      >
        {isSocialRegister() || isCompleteRegister() ? 'Concluir' : 'Avançar'}
      </FsButton>
    </form>
  );
};
