import {
  ButtonSize,
  FlagType,
  FsButton,
  FsButtonMini,
  FsFlag,
  IconPosition,
} from '@printi/ds-offset-react-core';
import { Heading, HeadingType } from '../Heading';

export const AccessEmail = () => {
  return (
    <form>
      <Heading
        heading="Acesse seu email e clique no link que enviamos para continuar com seu
          cadastro"
        description="Enviado para: charolotte@gmail.com"
        type={HeadingType.HeadingDescriptionButton}
        buttonMini={<FsButtonMini>Alterar email</FsButtonMini>}
      />
      <FsButton size={ButtonSize.LG} type="button">
        Reenviar
      </FsButton>
      <FsFlag
        heading="Não recebeu o link?"
        type={FlagType.Informative}
        iconPosition={IconPosition.Left}
      >
        Olhe sua caixa de spam, verifique se digitou o email correto ou aguarde
        para reenviar um novo link
      </FsFlag>
    </form>
  );
};
