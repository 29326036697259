/* eslint-disable import/order */
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { useValidationHooks } from '@mf/common/utils/hooks/index';
import { useFormik } from 'formik';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ToastType } from '@printi/ds-offset-react-core';
import { isValidIE } from '@brazilian-utils/brazilian-utils';
import bffCustomerData from '@mf/auth/repositories/bff/customer-data';
import bffAuth from '@mf/auth/repositories/bff/auth';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { TCustomerDataCompanyForm } from '@mf/auth/repositories/bff/customer-data/types';
import { TRegisterPayload } from '@mf/auth/repositories/bff/auth/types';

interface BffError extends AxiosError {
  response: AxiosResponse<{ message: string }>;
}

export const useLegalPersonHooks = () => {
  const {
    setRegisterData,
    setStep,
    setToast,
    setToastOpen,
    setToastType,
    baseUrl,
  } = useAuth();
  const { defaultField, phoneField, cnpjField } = useValidationHooks();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: session } = useSession();
  const customSession = session as CustomSession;

  const { useCustomerdata, editCustomerData } = bffCustomerData({
    baseUrl,
    token: customSession?.user?.token,
  });

  const { register } = bffAuth({ baseUrl });

  const { data: customerData } = useCustomerdata();

  const searchParams = useSearchParams();
  const { push } = useRouter();
  const queryClient = useQueryClient();

  const { mutate: mutateEditCustomer, isLoading: isLoadingCompleteRegister } =
    useMutation(editCustomerData, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['customer-data'],
          exact: true,
        });
        push(searchParams.get('callbackUrl') || '/');
      },
      onError: (error) => {
        const e = error as BffError;

        if (
          e?.response?.data?.message === 'Internal server error' ||
          e?.response?.status === 400
        ) {
          setToast('Erro ao atualizar os dados.');
          setToastType(ToastType.Negative);
          setToastOpen(true);

          queryClient.setQueryData(['customer-data'], null);
          signOut({
            redirect: false,
          });

          setTimeout(() => {
            setToast('');
            setToastOpen(false);
            queryClient.invalidateQueries({
              queryKey: ['customer-data'],
              exact: true,
            });
            push('/');
          }, 3000);
        }
      },
    });

  const { mutate: mutateUser, isLoading: isLoadingSocialRegister } =
    useMutation(register, {
      onSuccess: () => {
        const fpPromise = FingerprintJS.load();

        fpPromise
          .then((fp) => fp.get())
          .then(async (result) => {
            const fingerprint = result.visitorId;

            await signIn('credentials', {
              email: customSession?.profile?.email,
              password: '',
              fingerprint: fingerprint,
              redirect: true,
              callbackUrl: searchParams.get('callbackUrl') || '/',
              user_id: customSession?.profile?.id,
              token: customSession?.provider?.access_token,
              provider: customSession?.provider?.name,
            });
          });
      },
      onError: () => {
        setStep(LoginSteps.RecoverAccess);
        setToast(
          'Você já possui uma conta. Caso tenha esquecido sua senha, recupere o acesso.',
        );
        setToastType(ToastType.Negative);
        setToastOpen(true);
      },
    });

  const registerUser = useCallback(
    (payload: TRegisterPayload) => {
      mutateUser({ payload: payload });
    },
    [mutateUser],
  );

  const completeRegister = useCallback(
    (payload: TCustomerDataCompanyForm) => {
      mutateEditCustomer(payload);
    },
    [mutateEditCustomer],
  );

  const isCompleteRegister = useCallback(() => {
    return !!customerData?.id;
  }, [customerData?.id]);

  const isSocialRegister = useCallback(() => {
    return !!customSession?.profile?.id;
  }, [customSession?.profile?.id]);

  const verifyPhoneMask = useCallback((phone: string) => {
    if (phone.length === 13) {
      return 'phone';
    } else {
      return 'cellPhone';
    }
  }, []);

  const schema = Yup.object().shape({
    cnpj: Yup.string().test(
      'is-cnpj-valid',
      'Por favor, digite o CNPJ.',
      (value) => cnpjField(value),
    ),
    corporateReason: Yup.string().test(
      'is-company-name-valid',
      'Por favor, digite a razão social.',
      (value) => defaultField(value),
    ),
    phone: Yup.string().test(
      'is-phone-valid',
      'Por favor, digite o telefone',
      (value) => phoneField(value),
    ),
    numberOfEmployees: Yup.string().test(
      'is-employees-number-valid',
      'Informe quantos funcionários',
      (value) => defaultField(value, 1),
    ),
    stateRegistrationId: Yup.string().test(
      'is-state-registration-id-valid',
      'Informe a sigla do estado',
      (value) => defaultField(value),
    ),
    stateRegistration: Yup.string()
      .test(
        'is-state-registration-valid',
        'Informe o número de inscrição estadual',
        (value) => defaultField(value),
      )
      .test(
        'is-state-registration-id-valid',
        'Informe um número de inscrição estadual válido',
        (value, context) => {
          if (!value) {
            return true;
          }

          if (
            context.parent.stateRegistrationId !== undefined ||
            context.parent.stateRegistrationId !== 'Isento'
          ) {
            if (value.length > 6) {
              return isValidIE(context.parent.stateRegistrationId || '', value);
            }
            return true;
          } else {
            return true;
          }
        },
      ),
  });

  const formik = useFormik({
    initialValues: {
      cnpj: '',
      corporateReason: '',
      phone: '',
      numberOfEmployees: '',
      stateRegistrationId: '',
      stateRegistration: '',
      receiveOffers: false,
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (isCompleteRegister()) {
        const payload: TCustomerDataCompanyForm = {
          id: customerData?.id || '',
          first_name: customerData?.first_name || '',
          last_name: customerData?.last_name || '',
          email: customerData?.email || '',
          phone: values.phone || '',
          other_phone: customerData?.other_phone || '',
          cnpj: values.cnpj || '',
          company_name: values.corporateReason || '',
          trading_name: values.corporateReason || '',
          state_registration: values.stateRegistration || '',
          id_state_registration:
            values.stateRegistrationId == 'Isento'
              ? ''
              : values.stateRegistrationId || '',
          employee_number: values.numberOfEmployees || '',
          type: 'PJ',
        };
        completeRegister(payload);
      } else if (isSocialRegister()) {
        const fpPromise = FingerprintJS.load();

        fpPromise
          .then((fp) => fp.get())
          .then((result) => {
            const fingerprint = result.visitorId;

            const payload = {
              password: '',
              name: customSession?.profile?.name || '',
              email: customSession?.profile?.email || '',
              email_confirmation: customSession?.profile?.email || '',
              type: 'PJ',
              phone: values.phone || '',

              cnpj: values.cnpj || '',
              company_name: values.corporateReason || '',
              trading_name: values.corporateReason || '',
              state_registration: values.stateRegistration || '',
              id_state_registration:
                values.stateRegistrationId == 'Isento'
                  ? ''
                  : values.stateRegistrationId || '',
              employee_number: values.numberOfEmployees || '',

              terms_of_use: true,
              privacy_police: true,
              exclusive_offers_subscribe: values.receiveOffers,
              fingerprint: JSON.stringify({ fingerprint }),

              social_data: {
                id: customSession?.profile?.id || '',
                token: customSession?.provider?.access_token || '',
              },
              social_type: customSession?.provider?.name,
            };

            registerUser(payload);
          });
      } else {
        setIsLoading(true);
        setRegisterData((state) => ({
          ...state,
          cnpj: values.cnpj,
          phone: values.phone,
          corporateReason: values.corporateReason,
          numberOfEmployees: values.numberOfEmployees,
          stateRegistration: values.stateRegistration,
          stateRegistrationId:
            values.stateRegistrationId === 'Isento'
              ? ''
              : values.stateRegistrationId,
          receiveOffers: values.receiveOffers,
        }));

        await new Promise((resolve) => setTimeout(resolve, 1000));

        setStep(LoginSteps.CreatePassword);
        setIsLoading(false);
      }
    },
  });

  return {
    formik,
    isLoading,
    verifyPhoneMask,
    isCompleteRegister,
    customerData,
    isLoadingCompleteRegister,
    isLoadingSocialRegister,
  };
};
