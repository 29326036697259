'use client';

import { AccessEmail } from '@mf/auth/components/AccessEmail';
import { CreatePassword } from '@mf/auth/components/CreatePassword';
import { FormContainer } from '@mf/auth/components/FormContainer';
import { LegalPerson } from '@mf/auth/components/LegalPerson';
import { PhysicalPerson } from '@mf/auth/components/PhysicalPerson';
import { RecoverAccess } from '@mf/auth/components/RecoverAccess';
import { Register } from '@mf/auth/components/Register';
import { RegisterName } from '@mf/auth/components/RegisterName';
import { SignIn } from '@mf/auth/components/SignIn';
import { TypeAccount } from '@mf/auth/components/TypeAccount';
import React, { useEffect, useLayoutEffect } from 'react';
import { useAuth } from '@mf/common/contexts/auth/auth';
import { LoginSteps } from '@mf/common/contexts/auth/auth.types';
import { ResendMail } from '@mf/auth/components/ResendMail/ResendMail';
import { FsToast, ToastType } from '@printi/ds-offset-react-core';
import * as S from './Login.styles';
import useLoginHooks from './Login.hooks';

interface LoginScreenParams {
  preStep?: LoginSteps;
}

export const LoginScreen = ({ preStep }: LoginScreenParams) => {
  const [readyToRender, setReadyToRender] = React.useState(false);
  const { updateProgress } = useLoginHooks();
  const {
    setBaseUrl,
    step,
    setStep,
    toast,
    toastOpen,
    toastType,
    setToastOpen,
  } = useAuth();

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      setReadyToRender(true);
    }
  }, []);

  useEffect(() => {
    setBaseUrl(process.env.BFF_API_BASE_URL || '');
  }, [setBaseUrl]);

  useEffect(() => {
    updateProgress();
  }, [updateProgress]);

  useEffect(() => {
    if (!preStep) {
      return;
    }

    setStep(preStep);
  }, [preStep, setStep]);

  const getStep = () => {
    const steps = {
      [LoginSteps.SignIn]: <SignIn />,
      [LoginSteps.RecoverAccess]: <RecoverAccess />,
      [LoginSteps.Register]: <Register />,
      [LoginSteps.AccessEmail]: <AccessEmail />,
      [LoginSteps.RegisterName]: <RegisterName />,
      [LoginSteps.TypeAccount]: <TypeAccount />,
      [LoginSteps.PhysicalPerson]: <PhysicalPerson />,
      [LoginSteps.LegalPerson]: <LegalPerson />,
      [LoginSteps.CreatePassword]: <CreatePassword />,
      [LoginSteps.ResendMail]: <ResendMail />,
    };

    return steps[step];
  };

  return (
    <S.Container>
      {readyToRender && (
        <>
          <S.Body>
            <FormContainer>{getStep()}</FormContainer>
          </S.Body>
          <FsToast
            opened={toastOpen}
            type={toastType as ToastType}
            onButtonClick={() => setToastOpen(false)}
          >
            {toast}
          </FsToast>
        </>
      )}
    </S.Container>
  );
};
