// eslint-disable-next-line
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-3x);
  flex: 1 0 0;
  align-self: stretch;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-2x);
  align-self: stretch;
`;

export const WrapperParagraph = styled.div`
  display: flex;
  max-width: 480px;
  align-items: center;
  align-content: center;
  gap: var(--spacing-half) var(--spacing-1x);
  align-self: stretch;
  flex-wrap: wrap;
`;
